/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



/* CSS Related to APOLO */
$size_extra-large: 32px;

.apolo-panel-class {
    .mat-dialog-container {
      .dialog-wrapper {
        padding: $size_extra-large !important;
      }
    }
  }